<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="row full-width">
        <div class="col-md-3 fv-row">
          <el-input
              v-model="filters.search"
              class="m-2"
              size="large"
              placeholder="Please Input"
              :suffix-icon="Search"
          ></el-input>
        </div>
        <div class="col-md-3 fv-row">
          <el-select clearable v-model="filters.status" class="m-2 select-display-block" placeholder="Select Status"
                     size="large">
            <el-option
                v-for="item in filterOptions.statuses"
                :label="item.label"
                :value="item.value"
                :key="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6 fv-row mt-2">
          <el-cascader class="full-width" @change="handleCityChange" placeholder="Select Cities" :options="filterOptions.countries" :props="{multiple: true}" clearable filterable/>
        </div>

      </div>
      <div class="card-title full-width">

      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >

        <template v-slot:cell-image="{ row: data }">
          <div class="demo-type">
            <el-image
                class="image-in-list"
                :src="data.image"
                :preview-src-list="[data.image]"
                :initial-index="1"
                fit="cover"
            />
          </div>
        </template>
        <template v-slot:cell-first_name="{ row: data }">{{ data.first_name }}</template>
        <template v-slot:cell-last_name="{ row: data }">{{ data.last_name }}</template>
        <template v-slot:cell-phone="{ row: data }">{{ data.phone }}</template>
        <template v-slot:cell-email="{ row: data }">{{ data.email }}</template>
        <template v-slot:cell-city="{ row: data }">{{ data.city }}</template>
        <template v-slot:cell-otp_code="{ row: data }">{{ data.otp_code }}</template>
        <template v-slot:cell-phone_verified_at="{ row: data }">
          <span v-if="data.phone_verified_at">{{ data.phone_verified_at }}</span>
          <el-button v-else type="primary" size="small" @click="verifyPhone(data.id)">Verify</el-button>
        </template>
        <template v-slot:cell-is_active="{ row: data }">
                    <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.status.class}`">{{
                        data.status.label
                      }}</span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-row style="width: 200px;">
            <el-button type="default" v-can="'users.show'" size="small" @click="viewResource(data.id)" :icon="View" circle></el-button>
            <el-button type="primary" v-can="'users.update'" size="small" @click="editResource(data.id)" :icon="Edit" circle></el-button>
            <el-button type="danger"  v-can="'users.destroy'" size="small" @click="deleteResource(data.id)" :icon="Delete" circle></el-button>
          </el-row>


        </template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getCities} from "@/core/helpers/remote_data";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "users-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      // {name: "ID", key: "id", sortable: true,},
      {name: "Image", key: "image", sortable: false,},
      {name: "First Name", key: "first_name", sortable: true,},
      {name: "Last Name", key: "last_name", sortable: true,},
      {name: "Phone", key: "phone", sortable: false,},
      {name: "Email", key: "email", sortable: false,},
      {name: "City", key: "city", sortable: false,},
      {name: "Otp", key: "otp_code", sortable: false,},
      {name: "Phone Verified at", key: "phone_verified_at", sortable: false,},
      {name: "Is Active", key: "is_active", sortable: false,},
      {name: "Actions", key: "actions",},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      city_ids: '',
      date_range : [],
      page: 1,
      per_page: 10
    });

    const filterOptions = ref({statuses: [], countries: [], cities: []});
    const loadingCities = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Users", [], checkPermission('users.store') ? 'create-user': null);
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.USER.filter_options);
        filterOptions.value.statuses = response.data.data.statuses;
        filterOptions.value.countries = response.data.data.countries;
      } catch (e) {
        handleError(e)
      }
    }
    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.USER.list, filters.value);
        let data = response.data.data;
        tableData.value = data.users;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });

    const createResource = () => {
      router.push({name: 'create-user'});
    }
    const editResource = (id) => {
      router.push({name: 'edit-user', params: {id: id}});
    }
    const viewResource = (id) => {
      router.push({name: 'view-user', params: {id: id}});
    }
    const verifyPhone = async (id) => {
      var checkConfirm = await confirmGeneralMessage("Verify Phone", "Are you sure verify phone ?", "Verify");
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Pending ...'})
        ApiAxiosService.get(APIs.USER.verify_phone(id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
        }).catch(function (error) {
          handleError(error, 'swal');
        });

      }
    }
    const deleteResource = async (id) => {
      var checkConfirm = await confirmDelete();
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Pending ...'})
        ApiAxiosService.delete(APIs.USER.delete(id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
        }).catch(function (error) {
          handleError(error, 'swal');
        });

      }
    }

    // watch(() => filters.value.country_id, async (currentValue, oldValue) => {
    //   if(currentValue) {
    //     loadingCities.value = true;
    //     filterOptions.value.cities = [];
    //     filterOptions.value.cities = await getCities(currentValue);
    //     loadingCities.value = false;
    //   }
    //
    // });
    const handleCityChange = (value) => {
      filters.value.city_ids = Array.isArray(value)  ? value[value.length-1] : value;
    }
    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      fetchFilerOptions,
      filterOptions,

      createResource,
      editResource,
      viewResource,
      deleteResource,
      verifyPhone,
      loadingCities,
      handleCityChange,
      Search, Edit, Delete, View

    };
  },

});
</script>
